<template>
  <div class="page-wrapper">
    <section class="grid-wrapper">
      <SideBarUpcoming />
      <div>
        <static-block-component page="event" position="top" />
        <div class="content-wrapper main-container">
          <div class="options-wrapper">
            <div class="calendar-wrapper">
              <date-picker
                v-model="date"
                mode="date"
                class="date-pickers"
                :input-props="{ placeholder: 'From Date' }"
                :masks="{ input: 'YYYY-MM-DD' }"
                @dayclick="onDayClick"
              />
              <div class="icon-wrapper">
                <IconCalendar />
              </div>
            </div>
            <div v-if="teams.length" class="filtered-list-wrapper">
              <div class="search-icon">
                <IconSearch />
              </div>
              <FilteredList
                :filter-list="teams"
                @selected="onSelected"
                :getLabelCallback="getLabelForList"
              />
            </div>
          </div>
          <EventCategoryFilter v-bind:category-id.sync="selectedCategoryId" />
          <static-block-component page="event" position="middle" />
          <div v-if="eventList.length" class="card-wrapper">
            <c-w-event-card
              v-for="event in eventList"
              :key="event.id"
              :event="event"
              :selected-time-zone="timeZonesSelect"
            />
          </div>
          <h2 v-else-if="!processing" class="mt-5">
            {{ $t('There are no events yet') }}
          </h2>
          <loader v-show="processing" />
          <button-component
            :disabled="processing"
            v-if="canLoadMore && !processing"
            :text="$t('Show more')"
            @btn-click="showMoreHandle"
          />
        </div>
        <static-block-component page="event" position="bottom" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { QUERY, STREAM_STATUS } from '@/const';
import ButtonComponent from '../../components/base-components/buttons/ButtonComponent';
import Loader from '../../components/Loader';
import StaticBlockComponent from '../../components/StaticBlockComponent';
import CWEventCard from '@/league/components/EventCards/CWEventCard';
import EventCategoryFilter from '@/league/components/Filters/EventCategoryFilter';
import FilteredList from '@/components/base-components/Favorites/FilteredList';
import SideBarUpcoming from '@/components/base-components/Favorites/SideBarUpcoming';

// Icons
import IconSearch from '@/components/icons/Search';
import IconCalendar from '@/components/icons/Calendar';

export default {
  name: 'VodScreen',
  components: {
    FilteredList,
    EventCategoryFilter,
    CWEventCard,
    ButtonComponent,
    StaticBlockComponent,
    IconCalendar,
    IconSearch,
    SideBarUpcoming,
    Loader,
  },
  data() {
    return {
      requestParams: {
        limit: 12,
        offset: 0,
        sort_direction: 'desc',
        stream_status: [STREAM_STATUS.vod],
      },
      meta: {
        total: null,
        limit: null,
        offset: null,
      },
      date: null,
      eventList: [],
      canLoadMore: false,
      processing: false,
      timeZonesSelect: '',
      selectedCategoryId: this.getCategoryIdFromRoute(),
      selectedTeamId: null,
    };
  },
  watch: {
    date() {
      this.reset();
      this.getEventsList();
    },
    meta(newMeta) {
      const more = newMeta.total > newMeta.limit + newMeta.offset;
      more && (this.requestParams.offset += this.requestParams.limit);
      this.canLoadMore = more;
    },
    selectedCategoryId() {
      this.reset();
      this.getEventsList();
    },
    selectedTeamId() {
      this.reset();
      this.getEventsList();
    },
  },
  methods: {
    ...mapActions([
      'GET_EVENTS_LIST',
      'GET_EVENT_CATEGORIES',
      'GET_ALL_PARTICIPANTS',
    ]),
    getCategoryIdFromRoute() {
      const query = this.$route.query;
      const id =
        query[QUERY.GROUP] || query[QUERY.DIVISION] || query[QUERY.CHANNEL];
      return (id && Number(id)) || null;
    },
    reset() {
      this.requestParams.offset = 0;
      this.eventList = [];
    },
    onZoneSelected(timeZone) {
      this.timeZonesSelect = timeZone;
    },
    getRequestParams() {
      const params = Object.assign({}, this.requestParams);
      if (this.date) {
        params.start_date = parseInt(new Date(this.date) / 1000);
      }
      if (this.selectedCategoryId) {
        params.categories = [this.selectedCategoryId];
        params.recursively_categories = 1;
      }
      if (this.selectedTeamId) {
        params.participants = [this.selectedTeamId];
      }
      return params;
    },
    getEventsList() {
      this.processing = true;
      this.GET_EVENTS_LIST(this.getRequestParams())
        .then((res) => {
          this.eventList = [...this.eventList, ...res.data];
          this.meta = res.meta;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    showMoreHandle() {
      this.getEventsList();
    },
    onDayClick(e) {
      this.date = e.date;
    },
    onSelected(data) {
      this.selectedTeamId = data?.id;
    },
    getLabelForList(item) {
      const division = item.properties?.Division?.[0];
      const group = item.properties?.Group?.[0];
      const name = item?.name;
      if (!group && !division) return name;
      const concatSubName = [division, group].join(' | ');
      return `${name} - ${concatSubName}`;
    },
  },
  computed: {
    ...mapState({
      teams: (state) => state.participant.participantsList,
    }),
  },
  mounted() {
    this.getEventsList();
    this.GET_ALL_PARTICIPANTS();
    this.GET_EVENT_CATEGORIES();
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';

.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.filtered-list-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 2px;
  .search-icon {
    width: 3rem;
    height: 100%;
    z-index: 2;
    padding: 0.5rem;
    background: white;
    border-radius: 2px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.content-wrapper {
  padding: 1rem 0;
}

.page-title {
  font-size: 1.5rem;
  padding: 1.5rem 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  background-color: $card-background;
  flex-grow: 1;
  span {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
}
h1.page-title {
  margin-bottom: 0;
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
  font-size: 0.8rem;
  @include media-max(xs) {
    grid-template-columns: 1fr;
  }
}

.options-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 2rem;
  align-items: center;
  padding-bottom: 1rem;

  .date-pickers {
    min-width: 300px;
    text-transform: none;
  }
}
.select-zone {
  padding: 0.5rem 2rem;
  outline: none;
}
</style>

<style lang="scss">
.date-pickers {
  display: flex;
  input {
    font-size: 1.1rem;
    padding-left: 4rem;
    line-height: 1.5;
    border-radius: 4px;
    border: 0px;
  }
}
.calendar-wrapper {
  position: relative;
  .icon-wrapper {
    position: absolute;
    z-index: 2;
    width: 3rem;
    top: 1px;
    left: 1px;
    bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    svg {
      width: 100%;
      height: 70%;
    }
  }
}
</style>
