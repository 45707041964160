<template>
  <section>
  <div @click="onClick" class="filter-element">
    <div >
      {{ label() }}
    </div>
    <div @click.prevent="selectFavorites" v-if="userPermission && isLogged" class="filter-element-favorite">
      <StarIcon  :boolean="favorite" />
    </div>
  </div>
  <FavoritesPopup
      :popup="popup"
      :favorite="item"
      @closePopup="closePopup"
      @confirmPopup="deleteSelected"
      :key="item.id"
      :search="true"
    />
</section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import StarIcon from '@/components/icons/Star';
import FavoritesPopup from '@/components/base-components/Favorites/Popup';

export default {
  name: 'FilteredListNode',
  components: {
    StarIcon,
    FavoritesPopup,
  },
  data() {
    return {
      favorite: false,
      popup: false,
      key: 0,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    getLabelCallback: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters({
      getFavorites: 'getFavorites',
    }),
    ...mapState({
      userPermission: (state) => state.auth.user, // get user capabilities to check if he has the role to user the clipping tool
    }),
    isLogged(){
      return this.$store.getters.isLogged
    },
  },
    mounted() {
     this.favorite = this.getFavorites.some(
       (favorite) => favorite.favorite_id === this.item.id && favorite.favorite_type === "participant"
     );
  },
  methods: {
    ...mapActions(['POST_ADD_FAVORITE', 'GET_DELETE_FAVORITE']),
    onClick() {
      this.$emit('selected', this.item);
    },
    deletePopup() {
      this.popup = !this.popup;
    },
    closePopup() {
      this.popup = false;
    },
    deleteSelected() {
      this.GET_DELETE_FAVORITE({
        favorite_type: 'category',
        id: this.item.id,
      });
      this.closePopup();
    },
    selectFavorites() {
      if (this.userPermission) {
        this.favorite = !this.favorite;
        if (this.favorite) {
          this.POST_ADD_FAVORITE({
            favorite_id: this.item.id,
            favorite_type: 'participant',
            name: this.item.name,
          });
        } else {
          this.deletePopup()         
        }
      }
      // This need to send a favorited api call to populate vuex and list
    },
    label() {
      return this.getLabelCallback?.(this.item) || this.item.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-element {
  padding: 1rem 0.7rem;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  transition: all 0.09s linear;
  text-align: left;
  line-height: 1.1;
  margin-bottom: 0.2rem;
  font-style: normal;
  &:hover {
    background-color: var(--text-tertiary-light);
  }
}

.filter-element-favorite {
  margin-left: auto;
}
</style>
