<template>
  <div
    class="category-filter"
    v-if="loading || (!loading && categories.length)"
  >
    <template v-if="loading">
      <loader />
    </template>
    <template v-else>
      <SelectComponent
        :data="categories"
        label="Channel"
        prop-for-display="name"
        :disabled="false"
        v-bind:selectedValue.sync="selectedMainCategory"
      />
      <SelectComponent
        :data="divisionList"
        label="Division"
        prop-for-display="name"
        :disabled="!divisionList.length"
        v-bind:selectedValue.sync="selectedDivisionCategory"
      />
      <SelectComponent
        :data="groupList"
        label="Group"
        prop-for-display="name"
        :disabled="!groupList.length"
        v-bind:selectedValue.sync="selectedGroupCategory"
      />
    </template>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus';
import { mapState } from 'vuex';
import SelectComponent from '@/components/base-components/SelectComponent';
import Loader from '@/components/Loader';
import { QUERY } from '@/const';

const BLANK_FIELD = {
  name: '',
  id: null,
};

export default {
  name: 'EventCategoryFilter',
  components: { Loader, SelectComponent },
  props: {
    categoryId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedMainCategory: Number(this.$route?.query?.[QUERY.CHANNEL]) || null,
      selectedDivisionCategory:
        Number(this.$route?.query?.[QUERY.DIVISION]) || null,
      selectedGroupCategory: Number(this.$route?.query?.[QUERY.GROUP]) || null,
      selectedId: this.categoryId,
      loading: false,
    };
  },
  mounted() {
    EventBus.$on('resetPickedFavorite', () => {
      this.clearInputText();      
    });
  },
  beforeDestroy() {
    EventBus.$off('resetPickedFavorite');
  },
  methods: {
    emitCategory(newId) {
      this.$emit('update:categoryId', newId);
    },
    updateQuery(param, value) {
      const query = Object.assign({}, this.$route.query);
      value ? (query[param] = String(value)) : delete query[param];
    },
    clearInputText() {
      this.selectedMainCategory = null;
      this.selectedDivisionCategory = null;
      this.selectedGroupCategory = null;
    },
  },
  watch: {
    selectedMainCategory(newId) {
      this.selectedDivisionCategory = null;
      this.selectedGroupCategory = null;
      this.selectedId = newId;
      this.updateQuery(QUERY.CHANNEL, newId);
    },
    selectedDivisionCategory(newId) {
      this.selectedGroupCategory = null;
      this.selectedId = newId || this.selectedMainCategory;
      this.updateQuery(QUERY.DIVISION, newId);
    },
    selectedGroupCategory(newId) {
      this.selectedId =
        newId || this.selectedDivisionCategory || this.selectedMainCategory;
      this.updateQuery(QUERY.GROUP, newId);
    },
    selectedId(newId) {
      this.emitCategory(newId);
    },
  },
  computed: {
    ...mapState({
      categories: (state) => [BLANK_FIELD, ...state.event.eventCategories],
    }),
    divisionList() {
      const list = [];

      const selectedMainTree = this.categories.find(
        ({ id }) => Number(id) === Number(this.selectedMainCategory)
      );
      selectedMainTree?.children?.forEach((notDisplayedSubTree) => {
        notDisplayedSubTree?.children?.forEach((sub) => {
          list.push(sub);
        });
      });

      return list.length ? [BLANK_FIELD, ...list] : list;
    },
    groupList() {
      const list = [];
      const selectedMainTree = this.divisionList.find(
        ({ id }) => Number(id) === Number(this.selectedDivisionCategory)
      );
      selectedMainTree?.children?.forEach((sub) => {
        list.push(sub);
      });

      return list.length ? [BLANK_FIELD, ...list] : list;
    },
  },
};
</script>

<style scoped>
.category-filter {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
}
</style>
