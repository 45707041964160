<template>
  <div class="filter-wrapper" v-click-outside="onBlur">
    <input
      ref="input"
      v-model="search"
      @focus="onFocus"
      placeholder="Search Teams"
      class="filter-input"
    />
    <button v-show="search" @click="clearInput" class="clear-button">
      <CloseIcon />
    </button>
    <div v-show="show" class="filtered-list">
      <FilteredListNode
        v-for="item in displayedList"
        :key="`${item[valuePropName]}`"
        :item="item"
        @selected="onSelect(item)"
      />
    </div>
  </div>
</template>

<script>

import { EventBus } from "@/utils/event-bus";
import FilteredListNode from '@/components/base-components/Favorites/FilteredListNode';
import CloseIcon from '@/components/icons/Close';
export default {
  name: 'FilteredList',
  props: {
    filterList: {
      type: Array,
      required: true,
    },
    labelPropName: {
      type: String,
      default: 'name',
    },
    valuePropName: {
      type: String,
      default: 'id',
    },
    getLabelCallback: {
      type: Function,
    },
  },
  components: {
    FilteredListNode,
    CloseIcon,
  },
  data() {
    return {
      search: '',
      displayedList: [],
      show: false,
    };
  },
  watch: {
    search() {
      !this.search && this.unselect();
      this.getDisplayedList();
    },
  },
  mounted() {
    this.getDisplayedList();
    EventBus.$on('resetSearchFavorite', () => {
      this.clearInputText();
    });
  },
  beforeDestroy() {
    EventBus.$off('resetSearchFavorite');
  },
  methods: {
    clearInput() {
      this.show = false;
      this.search = '';
      this.$store.commit('UPDATE_SELECT_FAVORITE', '');
      EventBus.$emit('refreshFavoritesSpecific');
      //this.onSelect(null);
      
    },
    clearInputText() {
      this.show = false;
      this.search = '';
      //this.$emit('selected', ''');
    },
    getDisplayedList() {
      if (this.search) {
        this.displayedList = this.filterList.filter(
          ({ [this.labelPropName]: val }) => {
            return val.toLowerCase().includes(this.search.toLowerCase());
          }
        );
      } else {
        this.displayedList = this.filterList;
      }
    },
    onSelect(item) {
      if(item){
      this.search = item[this.labelPropName];
      this.onBlur();
      this.$emit('selected', item);
    }
    },
    onFocus() {
      this.show = true;
    },
    onBlur() {
      if (this.$refs.input !== document.activeElement) {
        this.show = false;
      }
    },
    unselect() {
      this.$emit('selected', null);
    },
    label(item) {
      return this.getLabelCallback?.(item) || item[this.labelPropName];
    },
  },
};
</script>

<style scoped lang="scss">
.filter-wrapper {
  position: relative;
  width: 100%;

  background-color: var(--text-secondary);
}
.filter-input {
  text-overflow: ellipsis;
  width: calc(100% - 2rem);
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 4px;
  padding: 0.5rem 0.7rem;
  outline: none;
  border: 0px solid var(--text-tertiary-middle);
  // font-style: italic;
}
.clear-button {
  position: absolute;
  top: 50%;
  right: 0.2rem;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--settings-watch-button-background);
}
.filtered-list {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  cursor: pointer;
  z-index: 100;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  max-height: 20rem;
  height: max-content;
  background-color: var(--text-secondary);
  box-shadow: var(--theme-box);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border: 0px solid var(--text-tertiary-middle);
  border-top-color: transparent;
}
</style>
