<template>
  <div>
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58911 3.25208C11.313 3.25208 12.9663 3.93689 14.1853 5.15588C15.4043 6.37487 16.0891 8.02817 16.0891 9.75208C16.0891 11.3621 15.4991 12.8421 14.5291 13.9821L14.7991 14.2521H15.5891L20.5891 19.2521L19.0891 20.7521L14.0891 15.7521V14.9621L13.8191 14.6921C12.6791 15.6621 11.1991 16.2521 9.58911 16.2521C7.8652 16.2521 6.2119 15.5673 4.99292 14.3483C3.77393 13.1293 3.08911 11.476 3.08911 9.75208C3.08911 8.02817 3.77393 6.37487 4.99292 5.15588C6.2119 3.93689 7.8652 3.25208 9.58911 3.25208ZM9.58911 5.25208C7.08911 5.25208 5.08911 7.25208 5.08911 9.75208C5.08911 12.2521 7.08911 14.2521 9.58911 14.2521C12.0891 14.2521 14.0891 12.2521 14.0891 9.75208C14.0891 7.25208 12.0891 5.25208 9.58911 5.25208Z"
        fill="var(--settings-registration-current-event-button-background)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "SearchIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
